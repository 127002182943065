@use "global"as *;

/***	Block - menu
----------------------------------------------------------***/
.menu {
    @include sp {
        line-height: 1;
        position: relative;
        font-weight: bold;
        @include fontSize_vw(7);
    }
}

.menu__link {
    @include sp {
        color: $color_blueGlay;
        text-align: center;
        display: block;
        text-decoration: none;
    }

    &::before {
        @include sp {
            color: $color_text;
            content: "";
            font-family: "Font Awesome 5 Pro";
            font-weight: $far;
            text-align: center;
            display: block;
            @include fontSize_vw(28);
        }
    }
}