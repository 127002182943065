@use "global"as *;

/***	Block - settlement
----------------------------------------------------------***/
.settlement {
    @include inside_section(settlement);
}

.settlement__body {
    @include inside_section_width();
}

@include numbers_table(settlement);