@use "global"as *;

/***	Block - member
----------------------------------------------------------***/
.member {
    @include inside_section(member);

    @include pc {
        @include inside_section_width();
        overflow: hidden;
    }

    @include sp {}
}

.member__figure {
    @include pc {
        float: right;
        margin-left: 1em;
        margin-bottom: 0.5em;
    }

    @include sp {
        text-align: center;
    }
}

.member__name {
    font-weight: bold;
    line-height: 1.4;

    @include pc {
        font-size: 2rem;
    }

    @include sp {
        font-size: 1.8rem;
        margin-top: 1em;
        text-align: center;
    }
}

.member__affiliation {
    @include pc {
        font-size: 1.8rem;
    }

    @include sp {
        font-size: 1.6rem;
    }
}

.member__body {
    margin-top: 1.5em;
}