@use "global"as *;

/***	Block - link
----------------------------------------------------------***/
.link {}

.link__headline {
    @include inside_main_headline("");
}

.link__item {
    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}