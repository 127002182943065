@use "global"as *;

/***	Block - news
----------------------------------------------------------***/
.news {
    @include index_headline(news);
    @include hrTop(1px, solid, $color_paleGlay, 50px, 8vw);
}

.news__container {
    @include pc {
        @include inside_section_width();
    }

    @include sp {}
}

.news__item {
    @include index_information(news);
}

.news__strong {
    color: $color_middleRed;
    font-size: 1.8rem;
}