@use "global"as *;

/***	Block - mainVisual
----------------------------------------------------------***/
$width_mainVisual_pc: calc(100vw - (250px + (20px * 2) + var(--scrollbar)));
$width_mainVisual_sp: calc(100vw - ((4vw * 2) + var(--scrollbar)));

.mainVisual {
    background: #7F7A75 url(/img/index_main_visual_01.jpg) center bottom/cover no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include pc {
        color: #fff;
        width: 100%;
        min-height: calc(#{$width_mainVisual_pc} * 0.5163);
        font-size: 1.3rem;
        margin-top: 20px;
        padding: #{"max(35px, 3.6458vw)"} 0;

        @include pc_1563px_ijo {
            // メインビジュアル1240px以上は幅固定
            height: 640px;
            min-height: unset;
        }
    }

    @include sp {
        min-height: calc(#{$width_mainVisual_sp} * 0.5163);
        margin-top: 2.6666vw;
    }
}

.mainVisual__headline {
    @include pc {
        width: 41.6666%;
        min-width: 280px;
        margin: 0 6%;
    }

    @include sp {
        width: 65.6716%;
        min-width: 220px;
        margin: 4.5%;
    }
}

.mainVisual__headlineImg {
    width: 100%;

    @include pc {}

    @include sp {}
}

.mainVisual__text_pc {
    @include pc {
        font-size: clamp(13px, 1.4583vw, 16px);
        max-width: 525px;
        margin: 25px 6.5% 0 6.5%;
    }

    @include sp {
        display: none;
    }
}

.mainVisual__text_sp {
    @include pc {
        display: none;
    }

    @include sp {
        margin-top: 2.6666vw;
    }
}