////	変数 - カラー
////----------------------------------------------------------
// 通常テキスト
$color_text: #333;
$color_subText: #7A7A7A;

// テキストリンク
$color_link: #009FAD;

// テーマ メインカラー
$color_theme_main: #F96900;

// その他カラー
$color_morePaleGlay: #f5f5f5;
$color_paleGlay: #DEDEDE;
$color_rightGlay: #B5B5B5;

$color_glay: #8A8A8A;
$color_blueGlay:#71888A;

$color_paleBeige: #FAF6F2;

$color_rightOrange: #F09452;
$color_deepOrange:#D95C00;

$color_middleGreen: #429A6E;
$color_middleBlue: #257AAC;
$color_middleRed: #DD4040;

////	変数 - 全体ウインドウ幅
////----------------------------------------------------------
$width_1281px: 1281px;
$width_1280px: 1280px;
$width_1279px: 1279px;
$width_1041px: 1041px;
$width_1040px: 1040px;
$width_1039px: 1039px;
$width_751px: 751px;
$width_750px: 750px;
$width_749px: 749px;

////	変数 - スクロールバー幅
////----------------------------------------------------------
$width_scrollbar: calc(100vw - 100%);

////	変数 - Font Awesome
////----------------------------------------------------------
$fas: 900; //solid
$far: 400; //regular
$fal: 300; //light
$fab: 400; //brands

////	変数 - Drawer Menu
////----------------------------------------------------------
$width_sd-menu: 85.3333vw;