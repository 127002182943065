@use "global"as *;

/***	Block - main
----------------------------------------------------------***/
.main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include pc {
        max-width: 1280px;
        padding: 20px 20px 70px 20px;
        flex-grow: 1;
    }

    @include sp {
        padding: 4vw 4vw 18.6666vw 4vw;

        .index & {
            padding-top: 2.6666vw;
        }
    }
}

.main__totalNumbers {
    @include pc {
        display: none;
    }

    @include sp {}
}