@use "global"as *;

/***	Block - category
----------------------------------------------------------***/
.category {
    @include inside_section(category);
}

$category_list_gap: 30px;

.category__list {
    @include inside_section_width();

    @include pc {
        display: flex;
        flex-wrap: wrap;
        gap: $category_list_gap;
    }

    @include sp {}
}

.category__item {
    @include pc {
        width: 100%;
        background-color: rgba($color_morePaleGlay, 0.8);
        padding: 20px;
    }

    @include sp {
        border-bottom: 1px dotted $color_rightGlay;
        padding: 5.3333vw 0;
    }

    .link__item_branch & {
        @include pc {
            width: calc((100% - #{$category_list_gap}) / 2);
        }

        @include sp {}
    }
}

.category__item_tokyo {
    @include pc {
        width: 100% !important;
    }

    @include sp {}
}

$category_list_second_gap: 20px;

.category__list_second {
    @include pc {
        background-color: rgba(#fff, 0.75);
        display: flex;
        flex-wrap: wrap;
        gap: $category_list_second_gap;
        margin-top: 15px;
        padding: $category_list_second_gap;
    }

    @include sp {
        margin-left: 1em;
    }

}

.category__item_second {
    @include pc {
        width: calc((100% - #{$category_list_second_gap}) / 2);
    }

    @include sp {
        margin-top: 1.5em;
    }
}