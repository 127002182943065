@use "global"as *;

/***	Block - victimLecture
----------------------------------------------------------***/
.victimLecture {
    @include inside_section(victimLecture);
}

.victimLecture__figure {
    text-align: center;
    margin-top: 1em;
}

.victimLecture__figcaption {
    text-align: center;
    color: $color_subText;
    font-size: 1.3rem;
}