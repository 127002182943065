@use "global"as *;

/***	Block - contact
----------------------------------------------------------***/
.contact {
    .header & {
        line-height: 1;
        position: relative;
        font-weight: bold;

        @include pc {
            font-size: 1.1rem;
            padding-bottom: 1px;
        }

        @include sp {
            @include fontSize_vw(7);
        }

        .contact__link {
            color: $color_blueGlay;
            display: block;
            text-align: center;
            text-decoration: none;

            @include pc {}

            @include sp {}

            &::before {
                color: $color_link;
                content: "";
                font-family: "Font Awesome 5 Pro";
                font-weight: $fas;
                text-align: center;
                display: block;

                @include pc {
                    font-size: 2.8rem;
                    margin-bottom: 0.2em;
                }

                @include sp {
                    @include fontSize_vw(25);
                    margin-top: 0.08em;
                    margin-bottom: 0.12em;
                }
            }
        }
    }

    .inside__goal_humanRights_summary .victimLecture & {
        @include inside_section_second(contact);
        margin-top: 3em !important;

        .contact__shipping {
            @include box(contact);
            margin-top: 1.5em;

            @include pc {
                max-width: 640px;
            }

            @include sp {}
        }

        .contact__subHead {
            @include inside_main_subHead(contact__subHead);
        }
    }

    .inside__goal_humanRights_summary .dvd & {
        @include box(contact);
        margin-top: 1.5em;

        @include pc {
            max-width: 640px;
        }

        @include sp {}

        .contact__headline {
            @include inside_main_subHead(contact__headline);
        }
    }

    .inside__feature_pleaseKnow .victimLecture & {
        @include inside_section_second(contact);
        margin-top: 3em !important;

        .contact__shipping {
            @include box(contact);
            margin-top: 1.5em;

            @include pc {
                max-width: 640px;
            }

            @include sp {}
        }

        .contact__subHead {
            @include inside_main_subHead(contact__subHead);
        }
    }

    .inside__feature_pleaseKnow .dvd & {
        @include box(contact);
        margin-top: 1.5em;

        @include pc {
            max-width: 640px;
        }

        @include sp {}

        .contact__headline {
            @include inside_main_subHead(contact__headline);
        }
    }
}