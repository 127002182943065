@use "global"as *;

/***	Block - why
----------------------------------------------------------***/
.why {
    background-color: #fff;
    border-radius: 10px 10px 0 0;

    @include pc {
        width: 100%;
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
        padding: 50px 40px;
    }

    @include sp {
        padding: 6.6666vw 4vw;
    }
}

.why__header {
    @include pc {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    @include sp {}
}

.why__figure {
    @include pc {
        width: 34.091%;
        max-width: 300px;
    }

    @include sp {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}

.why__image_01 {
    border: 1px solid #e5e5e5;
    box-shadow: 10px 10px 10px rgba(#000, 0.2);

    @include pc {
        width: 100%;
    }

    @include sp {}
}

.why__title {
    line-height: 1;

    @include pc {}

    @include sp {
        margin-top: calc(5.3333vw + 10px);
    }
}

.why__image_02 {
    width: 100%;
}

.why__text {
    margin-top: 1.5em;
}

.why__button {
    display: table;
    margin-left: auto;
    margin-right: auto;

    @include pc {
        margin-top: 2em;
    }

    @include sp {
        margin-top: 1em;
    }
}

.why__link {
    @include button($color_theme_main, "", $fas, 2.4rem, 4.8vw);
}

.why__table {
    @include table_dl_noThead_thLeft(15px, 4vw);
    margin-top: 3em;
}

.why__term {
    @include pc {}

    @include sp {
        text-align: center;
    }
}

.why__headline {
    @include feature_headline($color_theme_main);
}

.why__item {
    font-weight: bold;

    @include pc {
        font-size: 2rem;
    }

    @include sp {
        font-size: 1.8rem;
        line-height: 1.4;
    }

    &:not(:first-child) {
        margin-top: 1em;
    }
}

.why__list_second {
    list-style: disc;

    @include pc {
        margin-left: 1em;
    }

    @include sp {
        margin-left: 1.25em;
    }
}

.why__item_second {
    font-size: 1.6rem;
    font-weight: normal;

    @include pc {}

    @include sp {
        margin-top: 0.5em;
    }
}

.why__link_02 {
    margin-top: 1em;
    display: inline-block;
}

.why__link_image {
    border: 1px solid #e5e5e5;

    @include pc {
        max-width: 100%;
    }

    @include sp {}
}