@use "../base/valiable"as *;
@use "common"as *;
@use "inside_section_width"as *;

////	Mixin - button
////----------------------------------------------------------
@mixin button($background-color, $content, $font-weight, $font-size_pc, $font-size_sp) {
    color: #fff;
    background-color: $background-color;
    line-height: 1;
    display: inline-block;
    border-radius: 5px;
    text-decoration: none;
    position: relative;

    @include pc {
        font-size: $font-size_pc;
        padding: calc(#{$font-size_pc} * 1.25);
        padding-left: calc((#{$font-size_pc} * 1.5) + (#{$font-size_pc} * 1.25));
    }

    @include sp {
        font-size: $font-size_sp;
        padding: calc(#{$font-size_sp} * 1.25);
        padding-left: calc((#{$font-size_sp} * 1.5) + (#{$font-size_sp} * 1.25));
    }

    &::before {
        content: $content;
        font-family: "Font Awesome 5 Pro";
        font-weight: $font-weight;
        margin-right: 0.4em;
        position: absolute;

        @include pc {
            font-size: calc(#{$font-size_pc} * 1.5);
            top: 50%;
            transform: translateY(-50%);
            left: calc(#{$font-size_pc} * 1.25);
        }

        @include sp {
            font-size: calc(#{$font-size_sp} * 1.5);
            top: 50%;
            transform: translateY(-50%);
            left: calc(#{$font-size_sp} * 1.25);
        }
    }
}