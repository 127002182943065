@use "../base/valiable"as *;
@use "common"as *;

////	Mixin - globalNav
////----------------------------------------------------------
@mixin globalNav__item($content, $margin-left, $margin-right) {
    &::before {
        color: $color_deepOrange;
        content: $content;
        font-family: "Font Awesome 5 Pro";
        font-weight: $fal;
        margin-left: $margin-left;
        margin-right: $margin-right;

        @include pc {
            padding-left: 14px;
        }

        @include sp {
            padding-left: 4vw;
        }
    }

    @include pc {
        padding-top: 30px;
    }

    @include sp {
        padding-top: 4vw;
    }
}

@mixin globalNav__link($content, $margin-left, $margin-right) {
    @include globalNav__item($content, $margin-left, $margin-right);

    display: block;

    @include pc {
        padding-bottom: 30px;
    }

    @include sp {
        padding-bottom: 4vw;
    }
}

@mixin globalNav__second($content) {
    position: relative;
    display: block;

    @include pc {
        padding: 10px 0 10px 53px;
    }

    @include sp {
        padding: 5.3333vw 0 5.3333vw 14.6666vw;
    }

    &::before {
        content: $content;
        font-family: "Font Awesome 5 Pro";
        font-weight: $fas;
        position: absolute;

        @include pc {}

        @include sp {}
    }

    &::after {
        @include pc {}

        @include sp {
            width: 61.3333vw;
            height: 1px;
            content: "";
            border-top: 1px dotted $color_paleGlay;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

@mixin globalNav__second_borderTop {
    position: relative;

    &::before {
        content: "";
        display: block;
        border-top: 1px solid $color_paleGlay;
        position: absolute;
        right: 0;

        @include pc {
            width: 233px;
        }

        @include sp {
            width: 60.8vw;
            border: 1px solid $color_paleGlay;
        }
    }
}