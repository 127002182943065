@use "global"as *;

/***	Block - footerNav
----------------------------------------------------------***/
$footerNav_gap: 3.2rem;

.footerNav {
    @include pc {
        color: #fff;
        background-color: $color_rightOrange;
        padding: calc(50px - #{$footerNav_gap}) 20px;

        a {
            color: #fff;
            text-decoration: none;
        }
    }

    @include sp {
        display: none;
    }

}

.footerNav__list {
    @include pc {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: space-between;
        height: 31em;

    }

    @include sp {}
}

.footerNav__item {
    @include pc {
        font-weight: bold;
        line-height: 1;
        margin-top: $footerNav_gap;
    }

    @include sp {}
}

.footerNav__item_goal {
    @include pc {
        height: 100%;
    }

    @include sp {}
}

.footerNav__item_numbers {

    .br:before,
    .spbr:before {
        @include cssBrDisable();
    }
}

.footerNav__item_message {

    .br:before,
    .spbr:before {
        @include cssBrDisable();
    }
}

.footerNav__item_pleaseKnow {

    .br:before,
    .spbr:before {
        @include cssBrDisable();
    }
}

.footerNav__list_second {}

.footerNav__item_second {
    @include pc {
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 1.4;
        margin-top: 1.8rem;
    }

    @include sp {}

    .footerNav__item_goal & {
        @include pc {
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 1.4;
        }

        @include sp {}

        &:not(:first-child) {
            @include pc {
                margin-top: 3.2rem;
            }

            @include sp {}
        }
    }
}

.footerNav__list_third {}

.footerNav__item_third {
    @include pc {
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 1.4;
        margin-top: 1.2rem;
    }

    @include sp {}
}