@use "global"as *;

/***	Block - dentalPamphlet
----------------------------------------------------------***/
.dentalPamphlet {
    background-color: #fff;
    border-radius: 10px 10px 0 0;

    @include pc {
        width: 100%;
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
        padding: 50px 40px;
    }

    @include sp {
        padding: 6.6666vw 4vw;
    }
}

.dentalPamphlet__header {
    @include pc {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    @include sp {}
}

.dentalPamphlet__figure {
    @include pc {
        width: 34.091%;
        max-width: 300px;
    }

    @include sp {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}

.dentalPamphlet__image_01 {
    border: 1px solid #e5e5e5;
    box-shadow: 10px 10px 10px rgba(#000, 0.2);

    @include pc {
        width: 100%;
    }

    @include sp {}
}

.dentalPamphlet__title {
    line-height: 1;

    @include pc {}

    @include sp {
        margin-top: calc(5.3333vw + 10px);
    }
}

.dentalPamphlet__image_02 {
    width: 100%;
}

.dentalPamphlet__text {
    margin-top: 1.5em;
}

.dentalPamphlet__button {
    display: table;
    margin-left: auto;
    margin-right: auto;

    @include pc {
        margin-top: 2em;
    }

    @include sp {
        margin-top: 1em;
    }
}

.dentalPamphlet__link {
    @include button(#76c0ef, "", $fas, 2.4rem, 4.8vw);
}

.dentalPamphlet__table {
    @include table_dl_noThead_thLeft(15px, 4vw);
    margin-top: 3em;
}

.dentalPamphlet__term {
    @include pc {}

    @include sp {
        text-align: center;
    }
}

.dentalPamphlet__headline {
    @include feature_headline(#76c0ef);
}

.dentalPamphlet__item {
    font-weight: bold;

    @include pc {
        font-size: 2rem;
    }

    @include sp {
        font-size: 1.8rem;
        line-height: 1.4;
    }

    &:not(:first-child) {
        margin-top: 1em;
    }
}

.dentalPamphlet__list_second {
    list-style: disc;

    @include pc {
        margin-left: 1em;
    }

    @include sp {
        margin-left: 1.25em;
    }
}

.dentalPamphlet__item_second {
    font-size: 1.6rem;
    font-weight: normal;

    @include pc {}

    @include sp {
        margin-top: 0.5em;
    }
}

.dentalPamphlet__address {
    font-style: normal;
}

.dentalPamphlet__name {
    @include pc {
        font-size: 2rem;
    }

    @include sp {
        font-size: 1.8rem;
    }
}

.dentalPamphlet__old {
    @include pc {
        display: flex;
        align-items: center;
    }

    @include sp {}
}

.dentalPamphlet__figure_old {
    @include pc {
        width: 160px;
        margin-right: calc(2.6666vw + 10px);
    }

    @include sp {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}

.dentalPamphlet__image_old {
    border: 1px solid #e5e5e5;
    box-shadow: 10px 10px 10px rgba(#000, 0.2);

    @include pc {
        width: 100%;
    }

    @include sp {}
}

.dentalPamphlet__title_old {
    font-weight: normal;

    @include pc {}

    @include sp {
        margin-top: 1.5em;
    }

    >b {
        font-size: 1.8rem;
        line-height: 1.4;
    }
}


.dentalPamphlet__button_old {
    margin-top: 1.2em;

    @include pc {}

    @include sp {
        display: table;
        margin-left: auto;
        margin-right: auto;
    }
}

.dentalPamphlet__link_old {
    @include button(#ccc, "", $fas, 1.8rem, 4.26vw);

    .dentalPamphlet__old_01 & {
        background-color: #8fc31f;
    }
}