@use "global"as *;

/***	Block - content
----------------------------------------------------------***/
.content {}

.content__name {
    line-height: 1.4;
}

.content__url {
    line-height: 1.4;
}