@use "global"as *;

/***	Block - details
----------------------------------------------------------***/
.details {
    @include inside_section(details);
}

.details__list {
    margin: 1em 0;

    @include pc {}

    @include sp {}
}

.details__item {
    &:not(:first-child) {
        margin-top: 1em;

        @include pc {}

        @include sp {}
    }

    .caret {
        &::before {
            top: 0.2em;
            left: 1.35em;
        }
    }
}