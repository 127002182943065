@use "../base/valiable"as *;
@use "common"as *;

////	Mixin - table
////----------------------------------------------------------
// dl（theadなし、th左端）
@mixin table_dl_noThead_thLeft($padding_pc, $padding_sp) {
    width: 100%;
    border: 3px solid $color_paleGlay;
    display: table;
    border-collapse: collapse;

    >dl {
        display: table-row;
        border: 1px solid $color_paleGlay;

        @include pc {}

        @include sp {
            border-collapse: collapse;
        }

        >dt {
            background-color: rgba($color_paleGlay, 0.3);

            @include pc {
                display: table-cell;
                border: 1px dotted $color_rightGlay;
                padding: $padding_pc;
            }

            @include sp {
                border-bottom: 1px dotted $color_rightGlay;
                padding: $padding_sp;
            }
        }

        >dd {
            @include pc {
                display: table-cell;
                border: 1px dotted $color_rightGlay;
                padding: $padding_pc;
            }

            @include sp {
                padding: $padding_sp;
            }
        }
    }
}