@use "global"as *;

/***	Block - pleaseKnow
----------------------------------------------------------***/
.pleaseKnow {
    background-color: #fff;
    border-radius: 10px 10px 0 0;

    @include pc {
        width: 100%;
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
        padding: 50px 40px;
    }

    @include sp {
        padding: 6.6666vw 4vw;
    }
}

.pleaseKnow__header {
    @include pc {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 20px;
    }

    @include sp {}

    >.pleaseKnow__text {
        @include pc {
            width: 55%;
        }

        @include sp {
            margin-top: 1.5em;
        }
    }
}

.pleaseKnow__title {
    text-align: center;
}

.pleaseKnow__image_01 {
    @include pc {}

    @include sp {
        width: 65%;
    }
}

.pleaseKnow__victimLecture {
    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}

.pleaseKnow__dvd {
    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}