@use "global"as *;

/***	Block - js
----------------------------------------------------------***/
// jsでの操作のためだけに存在するブロック。
// jsが絡まないデザイン等の設定は別ブロックで

////////// Smart Drawer
@include sp {
    $width: 66.6666; //メニュー横幅をvwで
    $width_sd-menu: #{$width}vw;
    $max-width_sd-menu: calc(375px * (#{$width} / 100));

    #js__sd_menu {
        width: $width_sd-menu;
        height: 100%;
        position: fixed;
        top: 0;
        left: calc(#{$width_sd-menu} * -1);
        z-index: 99;
        overflow: auto;
        transition: transform 0.3s ease-out;

        &.block {
            transform: translate3d($width_sd-menu, 0, 0);
        }

        // 閉じるボタン
        .js__sd_close {
            cursor: pointer;
            display: block;
        }
    }

    // 開くボタン
    .js__sd_trigger {
        cursor: pointer;
    }

    // オーバーレイ
    #js__sd_overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 50;
        opacity: 0;
        background: rgba(#000, 0.7);

        &.block {
            right: 0;
            opacity: 1;
        }
    }
}

.js__headerHide {
    top: calc(var(--headerHeight) * -1);
}