@use "../base/valiable"as *;
@use "common"as *;

/***	Mixin - index_headline
----------------------------------------------------------***/
@mixin index_headline($className) {
    text-align: center;

    >* {
        text-align: left;
    }

    >.#{$className}__headline {
        display: inline-block;
        border-bottom: 3px solid $color_theme_main;
        line-height: 1;
        letter-spacing: 0.15em;
        margin-right: -0.15em;

        @include pc {
            font-size: 2.4rem;
            padding-bottom: 10px;
            margin-bottom: 40px;
        }

        @include sp {
            @include fontSize_vw(20);
            padding-bottom: 2vw;
            margin-bottom: 5.3333vw;
        }
    }
}