@use "../base/valiable"as *;
@use "common"as *;

////	Mixin - hrTop
////----------------------------------------------------------
// 1280px以上
@mixin hrTop($border-width, $border-style, $border-color, $margin_pc, $margin_sp) {
    border-top: $border-width $border-style $border-color;

    @include pc {
        margin-top: $margin_pc;
        padding-top: $margin_pc;
    }

    @include sp {
        margin-top: $margin_sp;
        padding-top: $margin_sp;
    }
}