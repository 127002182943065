@use "global"as *;

/***	Block - goal
----------------------------------------------------------***/
@include index_banner(goal);

.goal {
    @include index_headline(goal);
    @include hrTop(1px, solid, $color_paleGlay, 50px, 8vw);
}

.goal__read {
    @include pc {
        margin-left: 15px;
        margin-right: 15px;
    }

    @include sp {}
}

.goal__term {
    color: $color_middleGreen;
    background-color: #BBE6D1;

    .goal__item_permanentMeasures & {
        background-image: url(/img/index_permanent_measures_bg.svg);
    }

    .goal__item_humanRights & {
        background-image: url(/img/index_human_rights_bg.svg);
    }

    .goal__item_truth & {
        background-image: url(/img/index_truth_bg.svg);
    }
}