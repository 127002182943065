@use "common"as *;
@use "inside_section_width"as *;

////	Mixin - inside_section_second
////----------------------------------------------------------
@mixin inside_section_second($className) {
    &:not(:first-child) {
        margin-top: 1.5em;
    }

    .#{$className}__headline {
        @include pc {
            @include inside_section_width();
            font-size: 2rem;
        }

        @include sp {
            font-size: 1.7rem;
        }
    }

    .#{$className}__body {
        @include pc {}

        @include sp {}
    }

    .#{$className}__text {
        &:not(:first-child) {
            margin-top: 1.5em;
        }

        @include pc {
            @include inside_section_width();
        }

        @include sp {}
    }
}