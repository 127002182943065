@use "global"as *;

/***	Block - feature
----------------------------------------------------------***/
.feature {
    $width__item_pc: 320px;
    $gap__item_pc: 30px;

    .index & {
        @include index_headline(feature);
        @include hrTop(1px, solid, $color_paleGlay, 50px, 8vw);

        .feature__container {
            @include pc {
                gap: 0 3.125vw;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-top: calc((#{$gap__item_pc} * -1) + 30px);
            }

            @include sp {}

            /* pc flex最下部のコンテンツを左寄せに */
            &::after {
                @include pc {
                    width: $width__item_pc;
                    height: 0;
                    content: "";
                    display: block;
                }

                @include sp {}
            }

            &::before {
                @include pc {
                    width: $width__item_pc;
                    height: 0;
                    content: "";
                    display: block;
                    order: 1;
                }

                @include sp {}
            }
        }

        .feature__link {
            color: $color_text;
            border: 1px solid $color_paleGlay;
            border-radius: 5px;
            box-sizing: content-box;
            text-decoration: none;

            @include pc {
                display: flex;
                width: $width__item_pc;
                margin-top: $gap__item_pc;
            }

            @include sp {
                display: block;
                margin-top: 5.3333vw;
            }
        }

        .feature__term {
            line-height: 1;
        }

        .feature__bannerImage {
            border-radius: 4px 4px 0 0;

            @include pc {}

            @include sp {
                width: 100%;
                height: auto;
            }
        }

        .feature__description {
            @include pc {
                padding: 20px;
            }

            @include sp {
                padding: 5.3333vw;
            }
        }
    }

    .inside__goal_truth_dentistry & {
        .feature__container {
            @include pc {
                @include inside_section_width();
                display: flex;
                flex-wrap: wrap;
                gap: 30px 0;
                justify-content: space-evenly;
                margin-top: 60px;
            }

            @include sp {}
        }

        .feature__link {
            color: $color_text;
            border: 1px solid $color_paleGlay;
            border-radius: 5px;
            box-sizing: content-box;
            text-decoration: none;

            @include pc {
                display: flex;
                width: $width__item_pc;
            }

            @include sp {
                display: block;
                margin-top: 5.3333vw;
            }
        }

        .feature__term {
            line-height: 1;
        }

        .feature__bannerImage {
            border-radius: 4px 4px 0 0;

            @include pc {}

            @include sp {
                width: 100%;
                height: auto;
            }
        }

        .feature__description {
            @include pc {
                padding: 20px;
            }

            @include sp {
                padding: 5.3333vw;
            }
        }
    }
}