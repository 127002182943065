@use "global"as *;

/***	Block - consultation
----------------------------------------------------------***/
.consultation {
    @include inside_section(consultation);

    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}

.consultation__body {
    @include inside_section_width();
    overflow: hidden;
}

.consultation__figure {
    @include pc {
        float: right;
        margin-left: 1em;
        margin-bottom: 0.5em;
    }

    @include sp {
        text-align: center;
        margin-bottom: 1em;
    }
}

.consultation__figcaption {
    text-align: center;
    color: $color_subText;
    font-size: 1.3rem;
}