@use "../base/valiable"as *;
@use "common"as *;

////	Mixin - numbers内テーブル
////----------------------------------------------------------
@mixin numbers_table($className) {
    .#{$className}__table {
        @include pc {}

        @include sp {
            overflow-x: auto;
            position: relative;
        }

        &::before {
            @include pc {}

            @include sp {
                content: "表は左右にスクロールできます";
                color: #fff;
                background-color: $color_deepOrange;
                font-size: 1.4rem;
                line-height: 1;
                border-radius: 1em;
                display: inline-block;
                margin-bottom: 0.5em;
                padding: 0.2em 0.8em;
                position: sticky;
                top: 0;
                left: 0;
            }
        }

        table {
            width: 100%;
            border: 3px solid $color_paleGlay;
            border-collapse: collapse;

            @include pc {}

            @include sp {
                min-width: 640px;
            }
        }

        tr {
            &:nth-child(even) {
                background-color: rgba($color_paleGlay, 0.15);
            }

            &.condition {
                background-color: rgba($color_paleGlay, 0.3);
                border: 3px solid $color_paleGlay;
            }

            &.total {
                background-color: rgba($color_paleGlay, 0.3);
                border: 3px solid $color_paleGlay;
            }
        }

        th {
            width: calc(100% / 9);
            border-left: 1px dotted $color_rightGlay;
            border-right: 1px dotted $color_rightGlay;
            line-height: 1.4;
            white-space: nowrap;

            @include pc {
                padding: #{"min(1.5625vw, 15px)"};

            }

            @include sp {
                padding: 4vw;
            }
        }

        .condition th {
            text-align: center;
            line-height: 1.2;
        }

        .total th {
            text-align: center;
        }

        td {
            border-top: 1px solid $color_paleGlay;
            border-bottom: 1px solid $color_paleGlay;
            border-left: 1px dotted $color_rightGlay;
            border-right: 1px dotted $color_rightGlay;
            line-height: 1.4;

            @include pc {
                padding: #{"min(1.5625vw, 15px)"};
            }

            @include sp {
                padding: 4vw;
            }

            &:first-child {
                text-align: center;
                font-weight: bold;
                white-space: nowrap;

                @include pc {}

                @include sp {}
            }

            &:not(:first-child) {
                text-align: right;

                @include pc {}

                @include sp {}
            }
        }

        .total td {
            font-weight: bold;
        }
    }

    .#{$className}__note {
        text-align: right;
    }
}