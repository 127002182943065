@use "global"as *;

/***	Block - modal
----------------------------------------------------------***/
.modal {
    display: none;
}

.modal__close {
    color: #fff;
    background-color: $color_theme_main;
    display: table;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    padding: 0.5em 1em;
    cursor: pointer;
    transition: all 0.2s ease-out;

    @include pc {
        margin-top: 40px;
    }

    @include sp {
        margin-top: 5.3333vw;
    }

    &:hover {
        opacity: 0.5;
        filter: brightness(1.15);
    }

    &::before {
        content: "";
        font-family: "Font Awesome 5 Pro";
        font-weight: $far;
        margin-right: 0.3em;
    }
}

// Modaal本体CSS設定を上書き
.modaal-container {
    border-radius: 5px !important;
}