@use "global"as *;

/***	Block - layout
----------------------------------------------------------***/
// レイアウトのためだけに存在するブロック。
// 意味を持たないので親ブロック＋連番で管理する

.layout__main_01 {
    @include pc {
        width: 100%;
        display: flex;
    }

    @include sp {}
}

.layout__homeTopNav_01 {
    display: block;
    text-align: left;
}

.layout__footer_01 {
    @include pc {}

    @include sp {
        color: #fff;
        background-color: $color_rightOrange;
        text-align: center;
        padding: 8vw 4vw;
    }
}

.layout__article_01 {
    @include pc {
        margin-bottom: 40px;
    }

    @include sp {
        margin-bottom: 5.3333vw;
    }
}

.layout__dentalPamphlet_01 {
    @include pc {
        width: 61.364%;
    }

    @include sp {}
}

.layout__why_01 {
    @include pc {
        width: 61.364%;
    }

    @include sp {}
}