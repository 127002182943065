@use "global"as *;

/***	Block - sd (Smart Drawer)
----------------------------------------------------------***/
@include pc {

    .sd__trigger,
    .sd__close {
        display: none;
    }
}

@include sp {
    .sd__close {
        @include fontSize_vw(7);
        font-weight: bold;
        line-height: 1;
        border-bottom: 3px solid $color_paleGlay;
        padding: 4vw 5.3333vw 4vw 4.3333vw;
    }

    .sd__close_link {
        color: $color_blueGlay;
        line-height: 1;
        text-decoration: none;

        &::before {
            color: $color_text;
            @include fontSize_vw(33);
            content: "";
            font-family: "Font Awesome 5 Pro";
            font-weight: $fal;
            line-height: 0.8;
            display: block;
        }
    }
}