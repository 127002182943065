@use "global"as *;

/***	Block - dentalEnquete
----------------------------------------------------------***/
.dentalEnquete {
    @include pc {
        width: 100%;
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
    }

    @include sp {}
}

.dentalEnquete__header {
    @include pc {
        padding-top: 10px;
    }

    @include sp {
        padding-top: 5.3333vw;
    }

}

.dentalEnquete__title {
    text-align: center;
    line-height: 1.4;

    @include pc {
        font-size: 3.6rem;
    }

    @include sp {
        font-size: 2rem;
    }
}

.dentalEnquete__text {
    margin-top: 1.5em;

    .dentalEnquete__description & {
        margin-top: 1em;
    }
}

.dentalEnquete__button {
    background-color: #fff;
    border-radius: 5px;
    display: table;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
}

.dentalEnquete__link {
    @include button(#9abcd0, "", $fas, 2.4rem, 4.8vw);
}

.dentalEnquete__headline {
    @include feature_headline(#9abcd0);
}

.dentalEnquete__dlist {
    margin-top: 3em;
}

.dentalEnquete__term {
    @include pc {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 1.5em;
    }

    @include sp {}
}

.dentalEnquete__figure {
    @include pc {}

    @include sp {
        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }
}

.dentalEnquete__image {
    width: 100%;
}

.dentalEnquete__figcaption {
    text-align: center;
    line-height: 1.4;
    font-weight: bold;
    margin-top: 1em;
}

.dentalEnquete__answer {
    color: $color_middleRed;

    @include pc {
        font-size: 2.4rem;
    }

    @include sp {
        font-size: 1.8rem;
    }
}

.dentalEnquete__list {
    margin-top: 1.5em;
    list-style: disc;
    margin-left: 3em;
}

.dentalEnquete__item {
    font-size: 1.8rem;
    font-weight: bold;
}