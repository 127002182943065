@use "global"as *;

/***	Block - prefecture
----------------------------------------------------------***/
.prefecture {
    @include inside_section_width();
    border-bottom: 3px solid $color_paleGlay;

    &:first-of-type {
        border-top: 3px solid $color_paleGlay;
    }
}

.prefecture__header {
    position: relative;
    padding: 1.2em 1em;
    background-color: rgba($color_paleGlay, 0.3);
    cursor: pointer;

    .localGovernment__prefecture_okinawa & {
        cursor: auto;

        @include pc {}

        @include sp {
            padding-top: 1.81em;
            padding-bottom: 1.81em;
        }
    }
}

.prefecture__name {
    line-height: 1;

    @include pc {
        font-size: 2.4rem;
    }

    @include sp {
        font-size: 2rem;
    }
}

.prefecture__totalNumbers {
    line-height: 1;
    display: flex;

    @include pc {
        font-size: 1.4rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: #{"max(120px, 20%)"};
    }

    @include sp {
        width: 100%;
        font-size: 1.3rem;
        order: 1;
        margin-top: 0.5em;
    }
}

.prefecture__totalNumbers_municipal {
    &:nth-child(2) {
        &::before {
            content: "、";
        }
    }
}

.prefecture__toggle {
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.6rem;

    @include pc {
        padding-right: calc(1.6rem + 0.5em);
    }

    @include sp {
        font-size: 1.4rem;
        padding-right: calc(1.6rem + 0.3em);
    }

    &::after {
        color: $color_theme_main;
        content: "";
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-weight: $far;
        margin-left: 0.2em;
        right: 0;

        @include pc {
            font-size: 2.8rem;
        }

        @include sp {
            font-size: 2.2rem;
        }
    }

    &.active {
        &::after {
            content: "";
        }
    }
}

.prefecture__body {
    background-color: rgba($color_paleGlay, 0.15);
    border-top: 1px dotted $color_rightGlay;
    padding: 1.2em 1em;
    display: none;
}

.prefecture__dlist {
    &:nth-child(2) {
        margin-top: 1.5em;
    }
}

.prefecture__term {
    line-height: 1;
    font-weight: bold;
    margin-bottom: 0.7em;
}

.prefecture__item {
    &:not(:first-child) {
        margin-top: 1.5em;
    }
}

.prefecture__date {
    font-size: 1.4rem;
    line-height: 1;
}

.prefecture__councils {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6em 1em;
    margin-top: 0.6em;
}

.prefecture__council {
    line-height: 1;
}