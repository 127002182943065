@use "../base/valiable"as *;
@use "common"as *;

////	Mixin - feature_headline
////----------------------------------------------------------
@mixin feature_headline($color) {
    color: $color;
    border-bottom: 5px solid #ccc;
    line-height: 1.4;
    margin-top: 3em;
    margin-bottom: 1em;

    @include pc {
        font-size: 2.6rem;
    }

    @include sp {
        font-size: 2rem;
    }

    &::after {
        width: 50%;
        content: "";
        border-bottom: 5px solid $color;
        position: relative;
        top: 5px;
        display: block;
    }
}