@use "global"as *;

/***	Block - otherGroups
----------------------------------------------------------***/
.otherGroups {
    @include inside_section(otherGroups);
    margin-top: 1.5em;

    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}

.otherGroups__list {}

.otherGroups__item {
    &:not(:first-child) {
        margin-top: 1.5em;
    }
}

.otherGroups__subHead {
    @include inside_main_subHead(otherGroups);
}

.otherGroups__text {
    margin-top: 0 !important;
}