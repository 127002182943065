@use "../base/valiable"as *;
@use "common"as *;
@use "inside_section_width"as *;

////	Mixin - inside_section
////----------------------------------------------------------
@mixin inside_section($className) {
    text-align: center;

    >* {
        text-align: left;
    }

    .#{$className}__headline {
        display: inline-block;
        border-bottom: 3px solid $color_theme_main;

        @include pc {
            font-size: 2.4rem;
            line-height: 1.4;
            padding-bottom: 10px;
            margin-bottom: 40px;
        }

        @include sp {
            @include fontSize_vw(20);
            line-height: 1.4;
            padding-bottom: 2vw;
            margin-bottom: 5.3333vw;
        }
    }

    .#{$className}__text {
        @include pc {
            @include inside_section_width();

            &:not(:first-child) {
                margin-top: 1.5em;
            }
        }

        @include sp {
            &:not(:first-child) {
                margin-top: 1.5em;
            }
        }
    }
}