@use "global"as *;

/***	Block - data
----------------------------------------------------------***/
.data {
    border: 1px solid $color_paleGlay;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    @include pc {
        width: 140px;
        padding-left: 15px;
        padding-right: 15px;
    }

    @include sp {
        padding-top: 4vw;
        padding-bottom: 4vw;
    }
}

.data__headline {
    line-height: 1;
    white-space: nowrap;
    position: relative;
    top: 0.1em;
    margin-right: 0.3em;

    @include pc {
        font-size: 1.2rem;
    }

    @include sp {
        @include fontSize_vw(10);
    }
}

.data__body {
    line-height: 1;
    white-space: nowrap;

    @include pc {
        font-size: 1.2rem;
    }

    @include sp {
        font-size: 1rem;
        @include fontSize_vw(10);
    }

}

.data__number {
    margin-right: 0.2em;

    @include pc {
        font-size: 1.8rem;
    }

    @include sp {
        @include fontSize_vw(16);

        // スマホの場合のみ
        .sp & {
            letter-spacing: -0.05em;
        }
    }
}