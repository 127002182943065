@use "global"as *;

/***	Block - specialMeasures
----------------------------------------------------------***/
.specialMeasures {
    @include inside_section(specialMeasures);
}

.specialMeasures__figure {
    text-align: center;
}

.specialMeasures__image {
    @include pc {
        width: 560px;
        max-width: 80%;
    }

    @include sp {
        width: 75%;
    }
}

.specialMeasures__list {
    @include box(specialMeasures);
    margin: 1em 0;
}