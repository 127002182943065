@use "global"as *;

/***	Block - threeGroups
----------------------------------------------------------***/
.threeGroups {}

.threeGroups__headline {
    @include inside_main_headline("");
}

.threeGroups__text {
    @include pc {
        @include inside_section_width();
        margin-top: 1.5em;
    }

    @include sp {
        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }
}