////	Mixin - メディアクエリ
////----------------------------------------------------------
// 1563px以上
@mixin pc_1563px_ijo {
    @media screen and (min-width: 1563px) {
        @content;
    }
}

// 1280px以上
@mixin pc_1280px_ijo {
    @media screen and (min-width: 1280px) {
        @content;
    }
}

// 1280px未満
@mixin pc_1280px_miman {
    @media not screen and (min-width: 1280px) {
        @content;
    }
}

// 1040px以上
@mixin pc_1040px_ijo {
    @media screen and (min-width: 1040px) {
        @content;
    }
}

// 1040px未満
@mixin pc_1040px_miman {
    @media not screen and (min-width: 1040px) {
        @content;
    }
}

// PC & タブレット & 印刷（768px以上）
@mixin pc {

    @media print,
    screen and (min-width: 768px) {
        @content;
    }
}

// スマホ（768px未満）
@mixin sp {
    @media not screen and (min-width: 768px) {
        @content;
    }
}

// 375px以上
@mixin sp_375px_ijo {
    @media screen and (min-width: 375px) {
        @content;
    }
}

// 375px未満
@mixin sp_375px_miman {
    @media not screen and (min-width: 375px) {
        @content;
    }
}

////	Mixin - メディアクエリ - Retina対応
////----------------------------------------------------------
@mixin retina {

    @media screen and (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 2dppx) {
        @content;
    }
}

////	Mixin - hoverエフェクトなし
////----------------------------------------------------------
@mixin hoverNoEffect {
    opacity: 1;
    filter: brightness(1);
    text-decoration: none;
}

////	Mixin - css改行無効
////----------------------------------------------------------
@mixin cssBrDisable {
    content: "";
    white-space: normal;
}

////	Mixin - フォントサイズpx⇒vw変換
////----------------------------------------------------------
// 第一引数で基準となる画面幅のフォントサイズ、第二引数で基準となる画面幅を入力
@function get_vw($size, $viewport:375) {
    $rate: calc(100 / $viewport);
    @return $rate * $size * 1vw;
}

@mixin fontSize_vw($font_size:16) {
    font-size: $font_size * 1px;
    font-size: get_vw($font_size);
}