@use "../base/valiable"as *;
@use "common"as *;
@use "inside_section_width"as *;

////	Mixin - inside_main_subHead
////----------------------------------------------------------
@mixin inside_main_subHead($className) {
    @include pc {
        @include inside_section_width();
        font-size: 2rem;
    }

    @include sp {
        font-size: 1.7rem;
    }
}