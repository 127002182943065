@use "global"as *;

/***	Block - contentsList
----------------------------------------------------------***/
.contentsList {
    @include inside_section_second(contentsList);
    margin-top: 3em !important;
}

.contentsList__table {
    @include pc {
        @include inside_section_width();
        border: 3px solid $color_paleGlay;
        margin-top: 1em;
    }

    @include sp {}
}

.contentsList__head {
    @include pc {
        display: grid;
        grid-template-columns: repeat(15, 1fr);
        border-bottom: 3px solid $color_paleGlay;
        background-color: rgba($color_paleGlay, 0.3);
        align-items: center;
    }

    @include sp {
        display: none;
    }
}

.contentsList__head_item {
    @include pc {
        text-align: center;
        padding: 15px;
    }

    @include sp {}

    &:not(:first-child) {
        @include pc {
            border-left: 1px dotted $color_rightGlay;
        }

        @include sp {}
    }
}

.contentsList__head_condition {
    @include pc {
        grid-column: 1 / 14;
    }

    @include sp {}
}

.contentsList__head_settlementMoney {
    @include pc {
        grid-column: 14 / 16;
    }

    @include sp {}
}

.contentsList__group {
    @include pc {
        display: grid;
        grid-template-columns: repeat(15, 1fr);
    }

    @include sp {
        border: 3px solid $color_paleGlay;

        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }

    &:not(:first-of-type) {
        @include pc {
            border-top: 2px solid $color_paleGlay;
        }

        @include sp {}
    }

    >*:not(:first-child) {
        @include pc {
            border-left: 1px solid $color_paleGlay;
        }

        @include sp {}
    }
}

.contentsList__condition {
    @include pc {
        grid-column: 1 / 4;
        padding: 15px;
        display: grid;
        align-items: center;
    }

    @include sp {
        font-weight: bold;
        background-color: rgba($color_paleGlay, 0.3);
        text-align: center;
        border-bottom: 3px solid $color_paleGlay;
        padding: 4vw;
    }
}

.contentsList__settlementMoney {
    @include pc {
        grid-column: 4 / 16;
    }

    @include sp {}
}

.contentsList__amount {
    color: $color_middleRed;
    font-weight: bold;
    text-align: center;

    @include pc {
        display: grid;
        align-items: center;
    }

    @include sp {
        text-align: center;
        border-top: 1px dotted $color_rightGlay;
    }
}

.contentsList__affected {
    @include pc {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
    }

    @include sp {
        display: flex;
        align-items: center;
    }

    >* {
        @include pc {
            padding: 15px;
        }

        @include sp {
            flex-grow: 1;
            padding: 4vw;
        }
    }

    &:not(:first-child) {
        @include pc {
            border-top: 1px solid $color_paleGlay;
        }

        @include sp {
            border-top: 1px dotted $color_rightGlay;
        }
    }

    >*:not(:first-child) {
        @include pc {
            border-left: 1px dotted $color_rightGlay;
        }

        @include sp {}
    }

    >.contentsList__amount {
        @include pc {
            grid-column: 7 / 9;
        }

        @include sp {
            border: none;
        }
    }
}

.contentsList__exclusion {
    @include pc {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    }

    @include sp {}

    >*:not(:first-child) {
        @include pc {
            border-left: 1px dotted $color_rightGlay;
        }

        @include sp {}
    }

    &:not(:first-child) {
        @include pc {
            border-top: 1px solid $color_paleGlay;
        }

        @include sp {}
    }

    >.contentsList__elapsedTime {
        @include pc {
            grid-column: 1 / 11;
        }

        @include sp {}
    }

    >.contentsList__amount {
        @include pc {
            grid-column: 11 / 13;
        }

        @include sp {}
    }
}

.contentsList__exclusion_true {
    >.contentsList__elapsedTime {
        @include pc {
            padding: 15px;
        }

        @include sp {}

        .contentsList__group_stage3 &,
        .contentsList__group_stage2 & {
            @include pc {
                grid-column: 1 / 5;
            }

            @include sp {}
        }
    }

    >.contentsList__amount {

        .contentsList__group_stage3 &,
        .contentsList__group_stage2 & {
            color: inherit;
            font-weight: inherit;

            @include pc {
                text-align: inherit;
                grid-column: 5 / 13;
                border-left: 1px solid $color_paleGlay;
            }

            @include sp {
                border-top: 1px solid $color_paleGlay;
            }
        }

        .contentsList__group_stage4 &,
        .contentsList__group_stage1 & {
            @include pc {
                padding: 15px;
            }

            @include sp {
                padding: 4vw;
            }
        }
    }
}

.contentsList__exclusion_false {
    >* {
        @include pc {
            padding: 15px;
        }

        @include sp {
            padding: 4vw;
        }
    }
}

.contentsList__elapsedTime {
    @include pc {
        display: grid;
        align-items: center;
    }

    @include sp {
        background-color: rgba($color_paleGlay, 0.15);
        text-align: center;
        padding: 4vw;

        .contentsList__exclusion:not(:first-child) & {
            border-top: 2px solid $color_paleGlay;
        }
    }
}

.contentsList__currentStatus {
    @include pc {
        grid-column: 1 / 7;
        display: grid;
        align-items: center;
    }

    @include sp {
        width: 70%;
    }
}

.contentsList__note {
    font-size: 1.4rem;
    margin-top: 1em;

    @include pc {
        @include inside_section_width();
    }

    @include sp {}
}