@use "global"as *;

/***	Block - inside
----------------------------------------------------------***/
// 特集背景設定
.inside__feature_dentalPamphlet {
    background-image: linear-gradient(#AEDDF7 0%, #fff 50%, #fff 100%);
}

.inside__feature_dentalEnquete {
    background-image: repeating-linear-gradient(0deg, rgba(207, 225, 235, 0.5) 0px, rgba(207, 225, 235, 0.5) 1px, rgba(#000, 0) 1px, rgba(#000, 0) 20px), repeating-linear-gradient(90deg, rgba(207, 225, 235, 0.5) 0px, rgba(207, 225, 235, 0.5) 1px, rgba(#000, 0) 1px, rgba(#000, 0) 20px);
}

.inside__feature_why {
    background-image: linear-gradient(#fab17d 0%, #fff 50%, #fff 100%);
}

.inside__feature_pleaseKnow {
    background-image: linear-gradient(#fab17d 0%, #fff 50%, #fff 100%);
}

// 個別設定