@use "global"as *;

/***	Block - header
----------------------------------------------------------***/
.header {
    width: 100%;
    height: auto;
    background-color: #fff;
    position: fixed;
    top: 0;
    z-index: 10;
    transition: all 0.3s ease-out;
    border-bottom: clamp(3px, 0.8vw, 5px) solid $color_theme_main;

    @include pc {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
    }

    @include sp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.6666vw 1.6666vw 2vw 4vw;
    }
}

.header__title {
    line-height: 1;

    @include pc {
        margin-right: auto;
    }

    @include sp {
        width: 56vw;
        text-align: center;
    }
}

.header__link {
    display: block;
    text-decoration: none;
}

.header__logo {
    display: block;
    margin-left: auto;
    margin-right: auto;

    @include pc {
        width: 324px;
    }

    @include sp {
        width: 100%;
        max-width: 324px;
    }
}

.header__menu {
    @include pc {
        display: none;
    }

    @include sp {
        order: -1;
    }
}

.header__contact {
    white-space: nowrap;

    @include pc {
        margin-left: 20px;
        order: 1;
    }

    @include sp {}

}

.header__totalNumbers {
    @include pc {
        margin-left: 20px;
    }

    @include sp {
        display: none;
    }
}