@use "../base/valiable"as *;
@use "common"as *;

/***	Mixin - index_information
----------------------------------------------------------***/
@mixin index_information($className) {
    @include pc {
        display: flex;
        flex-wrap: wrap;
        margin-left: 20px;
        margin-right: 20px;
    }

    @include sp {}

    &:not(:first-child) {
        margin-top: 1em;
    }

    >.#{$className}__date {
        @include pc {
            width: 7em;
            flex-shrink: 0;
        }

        @include sp {}
    }

    >.#{$className}__body {
        @include pc {
            width: calc(100% - 7em);
            flex-grow: 1;
        }

        @include sp {}

        &:not(:first-of-type) {
            margin-top: calc(1em / 2);

            @include pc {
                margin-left: 7em;
            }

            @include sp {}
        }
    }
}