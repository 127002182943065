@use "../base/valiable"as *;
@use "common"as *;

/***	Mixin - index_banner
----------------------------------------------------------***/
@mixin index_banner($className) {
    $width__item_pc: 320px;
    $gap__item_pc: 30px;

    .#{$className}__container {
        @include pc {
            gap: 0 3.125vw;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: calc((#{$gap__item_pc} * -1) + 30px);
        }

        @include sp {}

        /* pc flex最下部のコンテンツを左寄せに */
        &::after {
            @include pc {
                width: $width__item_pc;
                height: 0;
                content: "";
                display: block;
            }

            @include sp {}
        }

        &::before {
            @include pc {
                width: $width__item_pc;
                height: 0;
                content: "";
                display: block;
                order: 1;
            }

            @include sp {}
        }
    }

    .#{$className}__link {
        color: $color_text;
        border: 1px solid $color_paleGlay;
        border-radius: 5px;
        text-decoration: none;

        @include pc {
            display: flex;
            width: $width__item_pc;
            margin-top: $gap__item_pc;
        }

        @include sp {
            display: block;
            margin-top: 5.3333vw;
        }
    }

    .#{$className}__item {
        @include pc {}

        @include sp {}
    }

    .#{$className}__term {
        font-weight: bold;
        line-height: 1.4;
        border-radius: 4px 4px 0 0;
        background-position: right bottom;
        background-repeat: no-repeat;
        display: table-cell;
        vertical-align: middle;

        @include pc {
            width: $width__item_pc;
            height: 160px;
            font-size: 2.4rem;
            padding: 0 30px;
        }

        @include sp {
            width: calc(100vw - (4vw * 2));
            height: calc((100vw - (4vw * 2)) / 2);
            background-size: cover;
            @include fontSize_vw(20);
            padding: 0 10.6666vw;
        }
    }

    .#{$className}__description {
        @include pc {
            padding: 20px;
        }

        @include sp {
            padding: 5.3333vw;
        }
    }
}