@use "global"as *;

/***	Block - activities
----------------------------------------------------------***/
.activities {}

.activities__headline {
    @include inside_main_headline("");
}

.activities__text {
    @include pc {
        @include inside_section_width();

        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }

    @include sp {
        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }
}