@use "global"as *;

/***	Block - exchange
----------------------------------------------------------***/
.exchange {
    @include inside_section(exchange);

    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}

.exchange__list {}

.exchange__item {
    &:not(:first-child) {
        margin-top: 1.5em;
    }
}

.exchange__subHead {
    @include inside_main_subHead(exchange);
}

.exchange__text {
    margin-top: 0 !important;
}