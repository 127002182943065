@use "global"as *;

/***	モジュール設定
----------------------------------------------------------***/
// 要確認マーク用
// <strong class="confirmation">文章のご確認をお願いします</strong>

.confirmation {
    color: #f60;
    display: block;

    &::before {
        color: #fff;
        background-color: #f00;
        content: "要確認";
        line-height: 1;
        padding: 0.2em 0.5em;
        border-radius: 5px;
        margin-right: 0.3em;
    }
}

/***	リンク（アイコン付与）
----------------------------------------------------------***/
.jpg {
    &::after {
        content: "";
        font-family: "Font Awesome 5 Pro";
        font-weight: $fas;
        margin-left: 0.2em;
    }

    // jpgかつ外部サイトの場合
    &.external {
        &::after {
            content: " ";
        }
    }
}

.pdf {
    &::after {
        content: "";
        font-family: "Font Awesome 5 Pro";
        font-weight: $fas;
        margin-left: 0.2em;
    }

    // pdfかつ外部サイトの場合
    &.external {
        &::after {
            content: " ";
        }
    }
}

.external {
    &::after {
        content: "";
        font-family: "Font Awesome 5 Pro";
        font-weight: $fas;
        margin-left: 0.2em;
    }
}

/***	リンク（行頭キャレット）
---------------------------------------------------------***/
.caret {
    position: relative;
    padding-left: 1em;

    &::before {
        content: "";
        font-family: "Font Awesome 5 Pro";
        font-weight: $fas;
        line-height: 1;
        position: absolute;
        top: 0.17em;
        left: 0.35em;
    }
}

/***	ol（丸数字）
---------------------------------------------------------***/
.circledNumber>li {
    list-style: none;
    text-indent: -1em;
    margin-left: 1em;
}

.circledNumber>li:first-child:before {
    content: "\2460";
}

.circledNumber>li:nth-child(2):before {
    content: "\2461";
}

.circledNumber>li:nth-child(3):before {
    content: "\2462";
}

.circledNumber>li:nth-child(4):before {
    content: "\2463";
}

.circledNumber>li:nth-child(5):before {
    content: "\2464";
}

.circledNumber>li:nth-child(6):before {
    content: "\2465";
}

.circledNumber>li:nth-child(7):before {
    content: "\2466";
}

.circledNumber>li:nth-child(8):before {
    content: "\2467";
}

.circledNumber>li:nth-child(9):before {
    content: "\2468";
}

.circledNumber>li:nth-child(10):before {
    content: "\2469";
}

.circledNumber>li:nth-child(11):before {
    content: "\2470";
}

.circledNumber>li:nth-child(12):before {
    content: "\2471";
}

.circledNumber>li:nth-child(13):before {
    content: "\2472";
}

.circledNumber>li:nth-child(14):before {
    content: "\2473";
}

.circledNumber>li:nth-child(15):before {
    content: "\2474";
}

.circledNumber>li:nth-child(16):before {
    content: "\2475";
}

.circledNumber>li:nth-child(17):before {
    content: "\2476";
}

.circledNumber>li:nth-child(18):before {
    content: "\2477";
}

.circledNumber>li:nth-child(19):before {
    content: "\2478";
}

.circledNumber>li:nth-child(20):before {
    content: "\2479";
}

/***	CSS改行
----------------------------------------------------------***/
.br::before {
    @include pc {
        content: "\A";
        white-space: pre;
    }
}

.spbr::before {
    @include sp {
        content: "\A";
        white-space: pre;
    }
}

/***	単語内改行禁止
----------------------------------------------------------***/
.noWrap {
    display: inline-block;
}

/***	不可視化
----------------------------------------------------------***/
.hidden {
    display: none;
}

/***	フォント関連（サイズ）
----------------------------------------------------------***/
.font12 {
    font-size: 1.2rem;
}

.font13 {
    font-size: 1.3rem;
}

.font14 {
    font-size: 1.4rem;
}

.font15 {
    font-size: 1.5rem;
}

.font16 {
    font-size: 1.6rem;
}

.font18 {
    @include pc {
        font-size: 1.8rem;
    }

    @include sp {
        font-size: 1.6rem;
    }
}

.font20 {
    @include pc {
        font-size: 2rem;
    }

    @include sp {
        font-size: 1.8rem;
    }
}

.font22 {
    @include pc {
        font-size: 2.2rem;
    }

    @include sp {
        font-size: 1.8rem;
    }
}

.font24 {
    @include pc {
        font-size: 2.4rem;
    }

    @include sp {
        font-size: 1.8rem;
    }
}

.font26 {
    @include pc {
        font-size: 2.6rem;
    }

    @include sp {
        font-size: 2rem;
    }
}

.font28 {
    @include pc {
        font-size: 2.8rem;
    }

    @include sp {
        font-size: 2rem;
    }
}

/***	マージン
----------------------------------------------------------***/
.mt00{margin-top: 0 !important;}
.mt10{margin-top: 10px !important;}
.mt20{margin-top: 20px !important;}
.mt30{margin-top: 30px !important;}
.mt40{margin-top: 40px !important;}
.mt50{margin-top: 50px !important;}
.mt100{margin-top: 100px !important;}

.mb00{margin-bottom: 0 !important;}
.mb10{margin-bottom: 10px !important;}
.mb20{margin-bottom: 20px !important;}
.mb30{margin-bottom: 30px !important;}
.mb40{margin-bottom: 40px !important;}
.mb50{margin-bottom: 50px !important;}
.mb100{margin-bottom: 100px !important;}

.ml00{margin-left: 0 !important;}
.ml10{margin-left: 10px !important;}
.ml20{margin-left: 20px !important;}
.ml30{margin-left: 30px !important;}
.ml40{margin-left: 40px !important;}
.ml50{margin-left: 50px !important;}
.ml100{margin-left: 100px !important;}

.mr00{margin-right: 0 !important;}
.mr10{margin-right: 10px !important;}
.mr20{margin-right: 20px !important;}
.mr30{margin-right: 30px !important;}
.mr40{margin-right: 40px !important;}
.mr50{margin-right: 50px !important;}
.mr100{margin-right: 100px !important;}

/***	フォント関連（カラー）
----------------------------------------------------------***/
.red {
    color: $color_middleRed;
}

/***	フォント関連（text-align）
----------------------------------------------------------***/
.center {
    text-align: center;
}

.right {
    text-align: right;
}

/***	マーカー
----------------------------------------------------------***/
.markerYellow {
    background-image: linear-gradient(transparent 20%, #e5ff00 20%);
}

/***	リスト関連
----------------------------------------------------------***/
.disc {
    list-style: disc;

    >li {
        margin-left: 1.2em;
    }
}