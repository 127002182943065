@use "global"as *;

/***	Block - language
----------------------------------------------------------***/
.language {
    display: flex;

    @include pc {}

    @include sp {
        justify-content: center;
        margin: 5.3333vw 4vw 0 4vw;
    }
}

.language__item {
    font-weight: bold;
    line-height: 1;
    text-align: center;

    @include pc {
        font-size: 1.4rem;

        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include sp {
        @include fontSize_vw(14);

        &:not(:first-child) {
            margin-left: 8vw;
        }
    }
}

.language__link {
    width: 100%;
    border: 1px solid #fff;
    border-radius: 5px;
    display: block;
    text-decoration: none;

    @include pc {
        width: 96px;
        padding: 8px 0;
    }

    @include sp {
        width: 25.6vw;
        padding: 2.1333vw 0;
    }

    &:hover {
        @include hoverNoEffect();
    }

    .language__item_japanese & {
        color: $color_text;
        background-color: #fff;
    }

    .language__item_english & {
        color: #fff;

        &:hover {
            color: $color_text;
            background-color: #fff;
        }
    }

}