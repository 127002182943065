@use "global"as *;

/***	Block - dentistry
----------------------------------------------------------***/
.dentistry {}

.dentistry__headline {
    @include inside_main_headline("");
}

.dentistry__body {
    text-align: center;

    >* {
        text-align: left;
    }

    @include pc {
        @include inside_section_width();
    }

    @include sp {}
}

.dentistry__subHead {
    display: inline-block;
    border-bottom: 3px solid $color_theme_main;
    line-height: 1;

    @include pc {
        font-size: 2.4rem;
        padding-bottom: 10px;
        margin-bottom: 40px;
    }

    @include sp {
        @include fontSize_vw(20);
        padding-bottom: 2vw;
        margin-bottom: 5.3333vw;
    }
}

.dentistry__text {
    @include pc {
        @include inside_section_width();

        &:not(:first-of-type) {
            margin-top: 1.5em;
        }
    }

    @include sp {
        &:not(:first-of-type) {
            margin-top: 1.5em;
        }
    }
}