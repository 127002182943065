@use "global"as *;

/***	Block - role
----------------------------------------------------------***/
.role {
    @include inside_section(role);

    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}

.role__information {}

.role__list {}

.role__item {
    // margin-top: 1.5em;
}

.role__subHead {
    // @include inside_main_subHead(role);
}

.role__text {
    margin-top: 0 !important;
}