@use "global"as *;

/***	Block - future
----------------------------------------------------------***/
.future {
    @include inside_section(future);
}

.future__list {
    @include pc {
        @include inside_section_width();

        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }

    @include sp {}
}