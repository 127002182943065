@use "global"as *;

/***	Block - ministerTalks
----------------------------------------------------------***/
.ministerTalks {}

.ministerTalks__headline {
    @include inside_main_headline("");
}

.ministerTalks__body {
    @include inside_section_width();
}

.ministerTalks__text {
    @include pc {
        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }

    @include sp {
        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }
}