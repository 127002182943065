@use "global"as *;

/***	Block - hitherto
----------------------------------------------------------***/
.hitherto {
    @include inside_section(hitherto);

    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}

.hitherto__list {}

.hitherto__item {
    &:not(:first-child) {
        margin-top: 1.5em;
    }
}

.hitherto__subHead {
    @include inside_main_subHead(hitherto);
}

.hitherto__text {
    margin-top: 0 !important;
}

.hitherto__subList {
    list-style: disc;
    @include inside_section_width();

    .hitherto__item_counseling & {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
}

.hitherto__subItem {
    list-style: disc;
    margin-left: 1.2em;

    &:not(:first-child) {
        margin-top: 0.5em;
    }

    .hitherto__item_counseling & {
        &:not(:first-child) {
            margin-top: 0;
        }
    }
}