@use "global"as *;

/***	Block - impression
----------------------------------------------------------***/
.impression {
    .victimLecture & {
        @include inside_section_second(impression);
        margin-top: 3em !important;
    }

    .inside__voice_plaintiff & {
        @include pc {
            margin-top: 100px;
        }

        @include sp {
            margin-top: 16vw;
        }
    }
}

.impression__headline {
    .inside__voice_plaintiff & {
        text-align: center;
        display: table;
        margin-left: auto;
        margin-right: auto;
        border-bottom: 3px solid $color_theme_main;
        line-height: 1;
        letter-spacing: 0.15em;
        padding-left: 0.15em;

        @include pc {
            font-size: 2.4rem;
            padding-bottom: 10px;
            margin-bottom: 40px;
        }

        @include sp {
            @include fontSize_vw(20);
            padding-bottom: 2vw;
            margin-bottom: 5.3333vw;
        }
    }
}

.impression__note {
    letter-spacing: 0;
    font-size: 1.4rem;
    font-weight: normal;
}

.impression__list {
    .victimLecture & {
        margin-top: 1.5em;
        list-style: disc;
        list-style-position: inside;
        text-indent: -0.8em;
        border-top: 2px dotted $color_paleGlay;

        @include pc {
            @include inside_section_width();
        }

        @include sp {}
    }
}

.impression__item {
    .victimLecture & {
        border-bottom: 2px dotted $color_paleGlay;
        margin-top: 0.8em;
        padding-left: 1.4em;
        padding-bottom: 0.6em;
    }

    .inside__voice_plaintiff & {
        background-color: rgba($color_morePaleGlay, 0.8);

        &:not(:first-of-type) {
            @include pc {
                margin-top: 50px;
            }

            @include sp {
                margin-top: 8vw;
            }
        }

        @include pc {
            @include inside_section_width();
            padding: 30px 20px;
        }

        @include sp {
            padding: 5.3333vw 4vw;
        }
    }
}

.impression__person {
    @include pc {
        margin-bottom: 10px;
    }

    @include sp {
        margin-bottom: 2vw;
    }

    &::before {
        font-family: "Font Awesome 5 Pro";
        font-size: 1.8rem;
        font-weight: $fas;
        content: "";
        margin-right: 0.5em;
    }
}