@use "global"as *;

/***	Block - message
----------------------------------------------------------***/
.message {}

.message__headline {
    .inside__message & {
        @include inside_main_headline("");
    }

    .inside__voice_message & {
        @include inside_main_headline("");
        line-height: 1.4;
    }
}

.message__subHead {
    font-weight: normal;

    @include pc {
        font-size: 1.8rem;
    }

    @include sp {
        font-size: 1.6rem;
    }
}