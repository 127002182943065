@use "global"as *;

/***	Block - article
----------------------------------------------------------***/
.article {
    @include inside_section(article);

    @include pc {
        @include inside_section_width();
    }

    @include sp {}
}

.article__body {
    &:not(:first-of-type) {
        margin-top: 3em;

        @include pc {}

        @include sp {}
    }
}

.article__headline {
    width: 100%;
    display: block;

    @include pc {
        margin-bottom: 10px !important;
    }

    @include sp {
        margin-bottom: 2vw !important;
    }

    .index & {
        @include pc {
            text-align: center;
        }

        @include sp {}
    }
}

.article__subHead {
    @include pc {
        font-size: 2.2rem;
    }

    @include sp {
        font-size: 1.8rem;
    }
}

.article__date {
    text-align: right;

    @include pc {
        font-size: 1.8rem;
        padding-right: 0.4em;
    }

    @include sp {}
}

.article__content {
    &:not(:first-child) {
        margin-top: 1.5em;
    }

    >iframe {
        @include pc {}

        @include sp {
            width: 100%;
            max-width: calc(100vw - (4vw * 2));
            height: calc((100vw - (4vw * 2)) * 0.5625);
        }
    }
}

.article__content_youtube {
    text-align: center;
}

.article__note {
    @include pc {}

    @include sp {
        text-align: left;
    }
}

.article__figure {
    text-align: center;
    margin-bottom: 1.5em;

    &:not(:first-child) {
        margin-top: 1.5em;
    }
}

.article__image {
    @include pc {}

    @include sp {
        max-width: 65% !important;
    }
}

.article__ended {
    border: 3px solid #ff0000;
    padding: 1em 1.4em;
    display: table;
    margin-left: auto;
    margin-right: auto;

    @include pc {
        font-size: 1.8rem;
    }

    @include sp {}
}