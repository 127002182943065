@use "global"as *;

/***	Block - leadLawyer
----------------------------------------------------------***/
.leadLawyer {
    @include inside_section(leadLawyer);

    @include pc {
        @include inside_section_width();
        overflow: hidden;
    }

    @include sp {}
}

.leadLawyer__figure {
    @include pc {
        float: right;
        margin-left: 1em;
        margin-bottom: 0.5em;
    }

    @include sp {
        text-align: center;
    }
}

.leadLawyer__read {
    @include pc {}

    @include sp {
        margin-top: 1em;
    }
}

.leadLawyer__name {
    font-weight: bold;
    line-height: 1.4;
    margin-top: 1em;

    @include pc {
        font-size: 2rem;
    }

    @include sp {
        font-size: 1.8rem;
    }
}

.leadLawyer__affiliation {
    @include pc {
        font-size: 1.8rem;
    }

    @include sp {
        font-size: 1.6rem;
    }
}

.leadLawyer__body {
    margin-top: 1.5em;
}

.leadLawyer__text_date {
    text-align: right;
}