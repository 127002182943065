/***	Print
----------------------------------------------------------***/
@media print {
    body {
        width: 100% !important;
        -webkit-print-color-adjust: exact !important;

        .cloneHeader {
            display: none !important;
        }
    }
}