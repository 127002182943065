@use "../base/valiable"as *;
@use "common"as *;

////	Mixin - inside_main_headline
////----------------------------------------------------------
@mixin inside_main_headline($content) {
    background-color: $color_morePaleGlay;
    text-align: center;
    line-height: 1;

    @include pc {
        font-size: 2.8rem;
        margin-top: 30px;
        margin-bottom: 70px;
        padding: 30px 0;
    }

    @include sp {
        font-size: 2rem;
        padding: 10.6666vw 0;
        margin-bottom: 8vw;
    }

    &::before {
        color: $color_deepOrange;
        font-family: "Font Awesome 5 Pro";
        content: $content;
        font-weight: $fal;
        display: block;

        @include pc {
            font-size: 3.6rem;
            margin-bottom: 15px;
        }

        @include sp {
            @include fontSize_vw(30);
            margin-bottom: 2.6666vw;
        }
    }

    ~*:not(:first-of-type) {
        @include pc {
            margin-top: 100px;
        }

        @include sp {
            margin-top: 18.6666vw;
        }
    }
}