@use "global"as *;

/***	Block - consideration
----------------------------------------------------------***/
.consideration {
    @include inside_section(consideration);

    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}

.consideration__text {
    margin-top: 0 !important;
}

.consideration__list {
    @include box(consideration__list);
    list-style: disc;
    margin-top: 1.5em;

    @include pc {
        padding-left: calc(20px + 1.2em);
    }

    @include sp {
        padding-left: calc(4vw + 1.2em);
    }
}

.consideration__item {
    &:not(:first-child) {
        margin-top: 1.5em;
    }
}

.consideration__item_note {
    font-size: 1.4rem;
}