@use "global"as *;

/***	Block - breadcrumb
----------------------------------------------------------***/
.breadcrumb {
    @include pc {}

    @include sp {
        display: none;
    }

    .inside__feature & {
        @include pc {
            margin-bottom: 30px;
        }

        @include sp {}
    }
}

.breadcrumb__list {
    @include pc {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 0.3em;
    }

    @include sp {}
}

.breadcrumb__item {
    @include pc {
        font-size: 1.4rem;
        line-height: 1;
    }

    @include sp {}

    &:not(:first-child) {
        &::before {
            @include pc {
                content: "";
                font-family: "Font Awesome 5 Pro";
                font-weight: $fal;
                margin-left: 0.6em;
                margin-right: 0.6em;
            }

            @include sp {}
        }
    }
}