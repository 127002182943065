@use "global"as *;

/***	Block - basicAgreement
----------------------------------------------------------***/
.basicAgreement {
    .inside__about_hepatitisBSuit & {
        @include inside_section(basicAgreement);
    }

    .inside__about_basicAgreement & {
        .basicAgreement__headline {
            @include inside_main_headline("");
        }
    }
}