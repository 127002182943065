@use "global"as *;

/***	Block - localGovernment
----------------------------------------------------------***/
.localGovernment {
    @include inside_section(localGovernment);

    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}

.localGovernment__text_read {
    color: $color_middleBlue;
    font-weight: bold;

    @include pc {
        font-size: 2.2rem;
    }

    @include sp {
        font-size: 1.8rem;
    }
}

.localGovernment__subHead {
    @include inside_main_subHead(localGovernment);
    text-align: center;
    margin-bottom: 1.5em;

    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}