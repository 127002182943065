@use "global"as *;

/***	Block - sideReader
----------------------------------------------------------***/
.sideReader {
}

.sideReader__headline {
    @include inside_main_headline("");
}
