@use "global"as *;

/***	Block - certificationStandards
----------------------------------------------------------***/
.certificationStandards {
    .inside__about_basicAgreement & {
        @include inside_section_second(certificationStandards);
    }

    .inside__courtGuide_requirement & {
        @include inside_section(certificationStandards);

        .certificationStandards__link {
            @include inside_section_width();
            display: block;
            text-align: center;
            margin-top: 1.5em;
        }

        .certificationStandards__link_image {
            border: 1px solid #e5e5e5;

            @include pc {
                max-width: 100%;
            }

            @include sp {}
        }
    }
}

.certificationStandards__list {
    @include box(certificationStandards);
    margin-top: 1.5em;
}