@use "global"as *;

/***	Block - signature
----------------------------------------------------------***/
.signature {}

.signature__headline {
    @include inside_main_headline("");
}

.signature__text {
    @include pc {
        @include inside_section_width();

        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }

    @include sp {
        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }
}