@use "global"as *;

/***	Block - dvd
----------------------------------------------------------***/
.dvd {
    @include inside_section(dvd);
}

.dvd__figure {
    text-align: center;
    margin-top: 1.5em;
}

.dvd__image {
    @include pc {}

    @include sp {
        max-width: 65% !important;
    }
}