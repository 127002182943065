@use "global"as *;

/***	Block - topics
----------------------------------------------------------***/
.topics {
    .index & {
        @include index_headline(topics);

        @include pc {
            margin-top: 50px;
        }

        @include sp {
            margin-top: 8vw;
        }

        .topics__container {
            @include pc {
                @include inside_section_width();
            }

            @include sp {}
        }

        .topics__item {
            @include index_information(topics);
        }
    }

    .inside__topics & {
        .topics__headline {
            @include inside_main_headline("");
        }

        .topics__container {
            @include pc {
                @include inside_section_width();
            }

            @include sp {}
        }

        .topics__item {
            @include pc {
                display: flex;
                padding: 20px;
            }

            @include sp {
                padding: 5.3333vw 0;
            }

            &:not(:first-child) {
                border-top: 1px dotted $color_rightGlay;
            }
        }

        .topics__date {
            font-weight: bold;

            @include pc {
                width: 7em;
                flex-shrink: 0;
            }

            @include sp {}
        }

        .topics__body {
            @include pc {
                width: calc(100% - 7em);
                flex-grow: 1;
            }

            @include sp {}
        }
    }
}