@use "../base/valiable"as *;
@use "common"as *;
@use "inside_section_width"as *;

////	Mixin - box
////----------------------------------------------------------
@mixin box($className) {
    background-color: rgba($color_morePaleGlay, 0.8);

    @include pc {
        @include inside_section_width();
        padding: 30px 20px;
    }

    @include sp {
        padding: 5.3333vw 4vw;
    }

    .#{$className}__item {
        &:not(:first-child) {
            margin-top: 1em;
        }

        .caret {
            &::before {
                top: 0.2em;
                left: 1.35em;
            }
        }
    }

    .#{$className}__headline,
    .#{$className}__subHead {
        color: $color_blueGlay;
        font-weight: bold;
        text-align: center;
    }

    .#{$className}__address {
        font-style: normal;
        margin-top: 1em;
    }

    .#{$className}__address_name {
        @include pc {
            font-size: 1.8rem;
        }

        @include sp {
            font-size: 1.7rem;
        }
    }
}