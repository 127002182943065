@use "global"as *;

/***	Block - intent
----------------------------------------------------------***/
.intent {
    @include inside_section(intent);

    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}

.intent__text {
    margin-top: 0 !important;
}