@use "global"as *;

/***	Block - homeTopNav
----------------------------------------------------------***/
.homeTopNav {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include pc {}

    @include sp {
        margin-top: 2.6666vw;
    }
}

.homeTopNav__pc {
    @include pc {}

    @include sp {
        display: none;
    }
}

.homeTopNav__sp {
    @include pc {
        display: none;
    }

    @include sp {}
}

.homeTopNav__item {
    width: 100%;
    line-height: 1;
    border: 1px solid $color_paleGlay;
    border-radius: 5px;
    display: flex;
    justify-content: center;

    @include pc {
        &:not(:first-child) {
            margin-left: 17px;
        }
    }

    @include sp {
        &:not(:first-child) {
            margin-left: 2.6666vw;
        }
    }
}

.homeTopNav__link {
    width: 100%;
    height: 100%;
    color: $color_text;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    @include pc {
        font-size: #{"max(1.5rem, 1.5625vw)"};
        padding: 20px 15px;
    }

    @include sp {
        @include fontSize_vw(12);
        padding: 2.6666vw;
    }

    &::before {
        color: $color_deepOrange;
        font-family: "Font Awesome 5 Pro";
        font-weight: $fal;
        display: block;
        margin-right: 0.5em;


        @include pc {
            font-size: 2.1rem;
        }

        @include sp {
            @include fontSize_vw(16);
        }
    }

    .homeTopNav__item_about & {
        &::before {
            content: "";
        }
    }

    .homeTopNav__item_courtGuide & {
        &::before {
            content: "";
        }
    }

    .homeTopNav__item_plaintiffs & {
        &::before {
            content: "";
        }
    }

    &:hover {
        background-color: rgba($color_rightOrange, 0.5);
        @include hoverNoEffect();

        &::before {
            transition: all 0.2s ease-out;
        }

    }
}