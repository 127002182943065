@use "global"as *;

/***	Block - read
----------------------------------------------------------***/
.read {
    width: 720px;
    margin-inline: auto;
    display: flex;
    justify-content: center;
    gap: 30px;
}

.read__headline {
    font-weight: normal;
    font-family: YuMincho, "Hiragino Mincho ProN", serif;
    @include pc {
        font-size: 3.6rem;
    }
    @include sp {
        font-size: 2rem;
    }
}
