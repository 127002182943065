////	Mixin
////----------------------------------------------------------
@use "mixin/_box.scss";
@use "mixin/_button.scss";
@use "mixin/_common.scss";
@use "mixin/_feature_headline.scss";
@use "mixin/_globalNav2.scss";
@use "mixin/_grid.scss";
@use "mixin/_hrTop.scss";
@use "mixin/_index_banner.scss";
@use "mixin/_index_headline.scss";
@use "mixin/_index_information.scss";
@use "mixin/_inside_main_headline.scss";
@use "mixin/_inside_main_subHead.scss";
@use "mixin/_inside_section_second.scss";
@use "mixin/_inside_section_width.scss";
@use "mixin/_inside_section.scss";
@use "mixin/_numbers_table.scss";
@use "mixin/_table.scss";;

////	Base
////----------------------------------------------------------
@use "base/valiable";
@use "base/base";
@use "base/module";

////	Block
////----------------------------------------------------------
@use "block/_activities.scss";
@use "block/_article.scss";
@use "block/_basicAgreement.scss";
@use "block/_breadcrumb.scss";
@use "block/_category.scss";
@use "block/_certificationStandards.scss";
@use "block/_chronology.scss";
@use "block/_consideration.scss";
@use "block/_consultation.scss";
@use "block/_contact.scss";
@use "block/_content.scss";
@use "block/_contentsList.scss";
@use "block/_data.scss";
@use "block/_dentalEnquete.scss";
@use "block/_dentalPamphlet.scss";
@use "block/_dentistry.scss";
@use "block/_details.scss";
@use "block/_dvd.scss";
@use "block/_exchange.scss";
@use "block/_feature.scss";
@use "block/_footer.scss";
@use "block/_footerNav.scss";
@use "block/_future.scss";
@use "block/_globalNav.scss";
@use "block/_goal.scss";
@use "block/_header.scss";
@use "block/_hepatitisBSuit.scss";
@use "block/_hitherto.scss";
@use "block/_homeTopNav.scss";
@use "block/_impression.scss";
@use "block/_information.scss";
@use "block/_inside.scss";
@use "block/_intent.scss";
@use "block/_js.scss";
@use "block/_language.scss";
@use "block/_layout.scss";
@use "block/_leadLawyer.scss";
@use "block/_leadPlaintiff.scss";
@use "block/_link.scss";
@use "block/_localGovernment.scss";
@use "block/_main.scss";
@use "block/_mainVisual.scss";
@use "block/_medicalLecture.scss";
@use "block/_member.scss";
@use "block/_menu.scss";
@use "block/_message.scss";
@use "block/_ministerTalks.scss";
@use "block/_modal.scss";
@use "block/_news.scss";
@use "block/_numbers.scss";
@use "block/_otherGroups.scss";
@use "block/_paper.scss";
@use "block/_plaintiff.scss";
@use "block/_plaintiffs.scss";
@use "block/_pleaseKnow.scss";
@use "block/_prefecture.scss";
@use "block/_print.scss";
@use "block/_proposal.scss";
@use "block/_read.scss";
@use "block/_requirement.scss";
@use "block/_role.scss";
@use "block/_sd.scss";
@use "block/_settlement.scss";
@use "block/_sideReader.scss";
@use "block/_signature.scss";
@use "block/_significance.scss";
@use "block/_specialMeasures.scss";
@use "block/_summary.scss";
@use "block/_threeGroups.scss";
@use "block/_topics.scss";
@use "block/_totalNumbers.scss";
@use "block/_victimLecture.scss";
@use "block/_voice.scss";
@use "block/_whatIs.scss";
@use "block/_why.scss";;

////	Print
////----------------------------------------------------------
@use "print/_print2.scss";;

////	Bug Fix
////----------------------------------------------------------
@use "bugfix/_ie11.scss";;

////	Google Font
////----------------------------------------------------------
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");