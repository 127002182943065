@use "global"as *;

/***	Block - plaintiff
----------------------------------------------------------***/
.plaintiff {
    .inside__courtGuide_numbers & {
        @include inside_section(plaintiff);
    }

    .inside__voice_plaintiff & {}
}

.plaintiff__body {
    .inside__courtGuide_numbers & {
        @include inside_section_width();
    }

    .inside__voice_plaintiff & {
        @include inside_section_width();
    }
}

.plaintiff__headline {
    .inside__voice_plaintiff & {
        @include inside_main_headline("");
    }
}

.plaintiff__text {
    &:not(:first-of-type) {
        margin-top: 1.5em;
    }
}

@include numbers_table(plaintiff);