@use "global"as *;

/***	Block - significance
----------------------------------------------------------***/
.significance {
    @include inside_section(significance);
}

.significance__list {
    list-style: none;
}

.significance__item {
    &:not(:first-child) {
        margin-top: 1.5em;
    }
}

.significance__subHead {
    @include inside_main_subHead(significance);

    .significance__item:first-child & {
        &:before {
            content: "\2460";
        }
    }

    .significance__item:nth-child(2) & {
        &:before {
            content: "\2461";
        }
    }

    .significance__item:nth-child(3) & {
        &:before {
            content: "\2462";
        }
    }
}

.significance__text {
    margin-top: 0 !important;
}