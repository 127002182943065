@use "global"as *;

/***	Block - information
----------------------------------------------------------***/
.information {
    @include box(information);
    margin-top: 1.5em;
}

.information__date {
    font-weight: bold;

    @include pc {
        font-size: 1.8rem;
    }

    @include sp {
        font-size: 1.7rem;
    }
}