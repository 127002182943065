@use "global"as *;

/***	Block - totalNumbers
----------------------------------------------------------***/
.totalNumbers {
    display: flex;

    @include pc {}

    @include sp {
        justify-content: space-between;
    }
}

.totalNumbers__headline {
    font-weight: bold;
    line-height: 1;
    text-align: center;
    align-self: center;
    white-space: nowrap;

    @include pc {
        font-size: 1.5rem;
        margin-right: 10px;
    }

    @include sp {
        @include fontSize_vw(14);
        margin-right: 3.4666vw;
    }
}

.totalNumbers__date {
    height: 100%;
    color: #8A8A8A;
    font-weight: normal;
    text-align: center;
    display: block;

    @include pc {
        font-size: 1rem;
        margin-top: 2px;
    }

    @include sp {
        @include fontSize_vw(8);
        margin-top: 1vw;
    }
}

.totalNumbers__body {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}

// PC 879px以下
@media screen and (min-width: 768px) and (max-width: 879px) {
    .totalNumbers__body {
        width: 140px;
    }
}

.totalNumbers__data_settlement {
    margin-left: -1px;
    margin-top: 0;
}

// PC 879px以下
@media screen and (min-width: 768px) and (max-width: 879px) {
    .totalNumbers__data_settlement {
        margin-left: 0;
        margin-top: -1px;
    }
}