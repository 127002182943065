@use "global"as *;

/***	Block - whatIs
----------------------------------------------------------***/
.whatIs {
    @include inside_section(whatIs);
}

.whatIs__figure {
    text-align: center;
}

.whatIs__image {
    @include pc {
        width: 360px;
    }

    @include sp {
        width: 75%;
    }
}