@use "global"as *;

/***	Block - summary
----------------------------------------------------------***/
.summary {
    .inside__goal_permanentMeasures_summary & {}

    .inside__goal_humanRights_summary & {}
}

.summary__headline {
    @include inside_main_headline("");
}

.summary__text {
    @include pc {
        @include inside_section_width();

        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }

    @include sp {
        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }
}