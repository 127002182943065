@use "global"as *;

/***	Block - plaintiffs
----------------------------------------------------------***/
@include index_banner(plaintiffs);

.plaintiffs {
    @include index_headline(plaintiffs);
    @include hrTop(1px, solid, $color_paleGlay, 50px, 8vw);
}

.plaintiffs__read {
    @include pc {
        margin-left: 15px;
        margin-right: 15px;
    }

    @include sp {}
}

.plaintiffs__term {
    color: $color_middleBlue;
    background-color: #BCD7E6;

    .plaintiffs__item_activities & {
        background-image: url(/img/index_activities_bg.svg);
    }

    .plaintiffs__item_ministerTalks & {
        background-image: url(/img/index_minister_talks_bg.svg);
    }

    .plaintiffs__item_chronology & {
        background-image: url(/img/index_chronology_bg.svg);
    }
}