@use "global"as *;

/***	Block - globalNav
----------------------------------------------------------***/
.globalNav {
    background-color: $color_paleBeige;

    @include pc {
        width: 250px;
        flex-shrink: 0;
    }

    @include sp {}
}

.globalNav__list {
    a {
        color: $color_text;
        text-decoration: none;

        &:hover {
            color: $color_deepOrange;
            @include hoverNoEffect();
        }
    }
}

.globalNav__item {
    font-weight: bold;
    line-height: 1;

    @include pc {
        font-size: 1.8rem;
    }

    @include sp {
        @include fontSize_vw(18);
    }

    &:not(:nth-child(2)) {
        border-top: 2px solid $color_paleGlay;
    }
}

.globalNav__item_home {
    display: none;
}

.globalNav__item_about {
    @include globalNav__item("", -0.05em, 0.7em);

    &::before {
        @include pc {
            font-size: 2.4rem;
        }

        @include sp {
            @include fontSize_vw(24);
        }
    }
}

.globalNav__item_courtGuide {
    @include globalNav__item("", 0, 0.8em);

    &::before {
        @include pc {
            font-size: 2.4rem;
        }

        @include sp {
            @include fontSize_vw(24);
        }
    }
}

.globalNav__item_plaintiffs {
    @include globalNav__item("", 0, 0.9em);

    &::before {
        @include pc {
            font-size: 2.1rem;
        }

        @include sp {
            @include fontSize_vw(21);
        }
    }
}

.globalNav__item_goal {
    @include globalNav__item("", 0, 0.55em);

    &::before {
        @include pc {
            font-size: 2.4rem;
        }

        @include sp {
            @include fontSize_vw(24);
        }
    }

    .globalNav__item_second {
        font-weight: bold;

        &:not(:first-child) {
            >.globalNav__item_inner {
                @include pc {
                    padding-top: 20px;
                }

                @include sp {}
            }
        }
    }
}

.globalNav__item_permanentMeasures {}

.globalNav__item_humanRights {
    @include globalNav__second_borderTop();
}

.globalNav__item_truth {
    @include globalNav__second_borderTop();
}

.globalNav__item_message {
    @include globalNav__item("", 0, 0.8em);

    &::before {
        @include pc {
            font-size: 2.4rem;
        }

        @include sp {
            @include fontSize_vw(24);
        }
    }
}

.globalNav__item_voice {
    @include globalNav__item("", -0.05em, 0.75em);

    &::before {
        @include pc {
            font-size: 2.2rem;
        }

        @include sp {
            @include fontSize_vw(22);
        }
    }
}

.globalNav__item_regionalCounseling {
    >.globalNav__link {
        @include globalNav__link("", 0, 0.7em);

        &::before {
            @include pc {
                font-size: 2.3rem;
            }

            @include sp {
                @include fontSize_vw(23);
            }
        }
    }
}

.globalNav__item_links {
    >.globalNav__link {
        @include globalNav__link("", 0, 0.75em);

        &::before {
            @include pc {
                font-size: 2.2rem;
            }

            @include sp {
                @include fontSize_vw(22);
            }
        }
    }
}

.globalNav__item_topics {
    >.globalNav__link {
        @include globalNav__link("", 0, 0.7em);

        &::before {
            @include pc {
                font-size: 2.3rem;
            }

            @include sp {
                @include fontSize_vw(23);
            }
        }
    }
}

.globalNav__item_feature {
    @include globalNav__item("", 0.25em, 1.03em);

    &::before {
        @include pc {
            font-size: 2.4rem;
        }

        @include sp {
            @include fontSize_vw(24);
        }
    }
}

.globalNav__list_second {
    @include pc {
        margin-top: 10px;
    }

    @include sp {
        margin-top: 4vw;
    }
}

.globalNav__item_second {

    font-weight: normal;
    line-height: 1.4;

    @include pc {
        font-size: 1.6rem;
    }

    @include sp {
        @include fontSize_vw(16);
    }

    &:last-child {
        >.globalNav__link_second {
            @include pc {
                padding-bottom: 30px;
            }

            @include sp {}
        }
    }
}

.globalNav__link_second {
    @include globalNav__second("");

    &::before {
        @include pc {
            left: 21px;
        }

        @include sp {
            left: 6.2vw;
        }
    }

}

.globalNav__item_inner {
    @include globalNav__second("");

    @include pc {
        padding-bottom: 7.5px !important;
    }

    @include sp {}

    &::before {
        @include pc {
            left: 18px;
        }

        @include sp {
            left: 5.3333vw;
        }
    }
}

.globalNav__item_third {
    &:last-child {
        >.globalNav__link_third {
            @include pc {
                padding-bottom: 20px;
            }

            @include sp {}
        }
    }
}

.globalNav__link_third {
    display: block;

    font-weight: normal;
    line-height: 1.4;
    position: relative;

    @include pc {
        font-size: 1.6rem;
        padding: 7.5px 0 7.5px 53px;
    }

    @include sp {
        @include fontSize_vw(16);
        padding: 5.3333vw 0 5.3333vw 14.6666vw;
    }

    &::before {
        content: "・";
        font-family: "Font Awesome 5 Pro";
        font-weight: $fas;
        position: absolute;

        @include pc {
            left: 15px;
        }

        @include sp {
            left: 4.6666vw;
        }

    }

    &::after {
        @include pc {}

        @include sp {
            width: 61.3333vw;
            height: 1px;
            content: "";
            border-top: 1px dotted $color_paleGlay;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}