@use "global"as *;

/***	Block - footer
----------------------------------------------------------***/
.footer {
    color: $color_glay;
    background-color: #333;
}

.footer__logo {
    .cls-1 {
        fill: #fff;
        stroke: #fff;
    }

    .cls-2 {
        fill: #fff;
    }

    @include pc {}

    @include sp {
        width: 56vw;
    }
}

.footer__container {
    @include pc {
        padding: 50px 20px;
        position: relative;
    }

    @include sp {
        padding-bottom: 8vw;
    }
}

.footer__leader {
    font-size: 1.3rem;
    line-height: 1;

    @include pc {}

    @include sp {}
}

.footer__copyright {
    line-height: 1.4;

    >small {
        font-size: 1.3rem;
    }

    @include pc {
        margin-top: 20px;
    }

    @include sp {
        margin: 8vw 4vw 0 4vw;
    }
}

.footer__language {
    @include pc {
        position: absolute;
        right: 20px;
        bottom: calc(50px + (1.4rem * 0.4));
    }

    @include sp {}

}