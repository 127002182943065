@use "global"as *;

/***	Block - print
----------------------------------------------------------***/
.print {
    @include inside_section(print);

    @include pc {
        margin-top: 100px;
    }

    @include sp {
        margin-top: 18.6666vw;
    }
}

.print__list {}

.print__item {
    &:not(:first-child) {
        margin-top: 1.5em;
    }
}

.print__subHead {
    @include inside_main_subHead(print);
}

.print__text {
    margin-top: 0 !important;
}

.print__figure {
        text-align: center;
        margin-top: 1.5em;
}

.print__image {
    @include pc {
        width: 280px;
        max-width: 80%;
    }

    @include sp {
        width: 75%;
    }
}
