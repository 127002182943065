@use "global"as *;

/***	デフォルト設定
----------------------------------------------------------***/
*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin-top: var(--headerHeight) !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
    -webkit-text-size-adjust: 100%;
}

body {
    color: $color_text;
    min-height: 100vh;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.8;
    scroll-behavior: smooth;
    overflow-wrap: break-word;
    word-wrap: break-word;

    @include pc {}

    @include sp {}
}

input,
textarea {
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
    font-size: 1.6rem;
    -webkit-appearance: none;
    border-radius: 0;
}

h1 {
    @include pc {
        font-size: 3.2rem;
    }

    @include sp {
        font-size: 2rem;
    }

}

h2 {
    @include pc {
        font-size: 2.4rem;
    }

    @include sp {
        font-size: 1.8rem;
    }
}

h3 {
    @include pc {
        font-size: 1.9rem;
    }

    @include sp {
        font-size: 1.6rem;
    }
}

h5 {
    @include pc {
        font-size: 1.6rem;
    }

    @include sp {
        font-size: 1.6rem;
    }
}

h6 {
    @include pc {
        font-size: 1.6rem;
    }

    @include sp {
        font-size: 1.6rem;
    }

}

img {
    vertical-align: bottom;
}

ul,
ol {
    list-style: none;
}

//iOS
input[type="submit"],
input[type="button"] {
    border-radius: 0;
    -webkit-box-sizing: content-box;
    -webkit-appearance: button;
    appearance: button;
    border: none;
    box-sizing: border-box;

    &::-webkit-search-decoration {
        display: none;
    }

    &::focus {
        outline-offset: -2px;
    }
}

/* 視差効果を減らす設定にしている場合 */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

/***	画像
----------------------------------------------------------***/
.inside img {
    @include pc {}

    @include sp {
        max-width: 100%;
    }
}

/***	リンク
----------------------------------------------------------***/
a {
    color: $color_link;
    text-decoration: underline;
    transition: all 0.2s ease-out;
    word-break: break-all;

    &:hover {
        text-decoration: none;
        opacity: 0.5;
        filter: brightness(1.15);
    }

    &:hover img {
        opacity: 0.7;
    }
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

a[href^="tel:"] {
    .pc & {
        color: $color_text;
        text-decoration: none;

        &:hover {
            @include hoverNoEffect;
            cursor: text;
        }
    }
}